<template>
    <h-row justify="center">
        <h-col class="py-0">

            <h-row class="pt-6 pb-3" justify="center" align="center">
                <h-col cols="auto">
                    <h1>{{ utente.NOME }} {{ utente.COGNOME }}</h1>
                </h-col>
            </h-row>

            <base-scheda-attributo class="pt-0" titolo="Incarichi">

                <h-col v-if="isLoading" cols="11" sm="10">
                    <loading-resouces icon="fa-tasks"
                                      messaggio="Recupero incarichi in corso"
                    ></loading-resouces>
                </h-col>

                <h-col v-else-if="incarichi && incarichi.length" class="pt-0" cols="11" sm="10">
                    <h-row justify="space-around" align="center">

                        <h-col v-for="(incarico, index) in incarichi"
                               :key="index"
                               cols="11"
                               sm="5"
                               class="py-0 mb-4"
                               style="border: 1px solid dimgray; border-radius: 4px;"
                        >

                            <h2 class="pa-4 text-center">
                                #{{ incarico.ID_REC }} - {{ incarico.TYPE }} ({{ incarico.ID_EVENTO_FK }})
                            </h2>

                            <v-divider></v-divider>

                            <p class="pa-4 mb-0">
                                Incarico assegnato da {{ incarico.CREATOR }}
                                il {{ getData(incarico.CREATOR_TIMESTAMP) }}
                            </p>

                            <v-divider></v-divider>

                            <h-row class="w-100 pt-6 pb-4">

                                <h-col cols="6" no-padding>
                                    <button-tooltip block
                                                    icon-name="fa-chevron-circle-right"
                                                    color="primary"
                                                    min-height="100%"
                                                    :disabled="!!isGlobalLoading"
                                                    tooltip="Click per visualizzare la scheda evento"
                                                    :to="'/secure/eventi/scheda?id=' + incarico.ID_EVENTO_FK"
                                                    exact
                                    ></button-tooltip>
                                </h-col>

                                <h-col cols="6" no-padding>
                                    <button-tooltip block
                                                    icon-name="fa-check"
                                                    color="success"
                                                    min-height="100%"
                                                    :disabled="!!isGlobalLoading"
                                                    tooltip="Click per confermare e chiudere l'incarico"
                                                    @click.stop="closeIncarico(incarico)"
                                    ></button-tooltip>
                                </h-col>

                            </h-row>

                        </h-col>

                    </h-row>
                </h-col>

                <no-data-available v-else></no-data-available>

            </base-scheda-attributo>

            <conferma-dialog v-if="Boolean(dialog)"
                             :dialog="Boolean(dialog)"
                             :text="'Sei sicuro di voler chiudere l\'incarico #' + dialog.ID_REC + '?'"
                             @close="dialog = false"
                             @confermato="closeIncarico"
            ></conferma-dialog>

        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseSchedaAttributo from '@/components/aaaProject/scheda/BaseSchedaAttributo';
    import LoadingResouces from '@/components/aaaGenerics/commons/LoadingResouces';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import NoDataAvailable from '@/components/aaaGenerics/commons/NoDataAvailable';
    import ConfermaDialog from '@/components/aaaGenerics/commons/ConfermaDialog';
    import moment from '@/moment';

    export default {
        name: 'Incarichi',
        components: {ConfermaDialog, NoDataAvailable, ButtonTooltip, LoadingResouces, BaseSchedaAttributo, HCol, HRow},
        data: () => ({
            dialog: false,
            isLoading: false,
            apiTimer: false,
            incarichi: []
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            utente: function () {
                return this.$store.state.authModule.utente;
            }
        },
        methods: {
            loadIncarichi() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET degli incarichi
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_INCARICHI',
                            doResponse: incarichi => _this.incarichi = incarichi,
                            doClose: () => _this.isLoading = false
                        });
                    }
                }

                this.isLoading = true;
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);

            },
            closeIncarico(incarico) {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET degli incarichi
                        _this.$store.dispatch('defaultRequest', {
                            api: 'DELETE_INCARICHI',
                            paylod: _this.dialog.ID_REC,
                            doResponse: () => {

                                _this.$store.dispatch('activeSnackbar', {
                                    color: 'success',
                                    text: 'Incarico #' + _this.dialog.ID_REC + ' chiuso con successo'
                                });

                                _this.incarichi = _this.incarichi.filter(i => i.ID_REC !== _this.dialog.ID_REC);

                            },
                            doClose: () => _this.dialog = false
                        });
                    }
                }

                if (!this.dialog) {
                    return this.dialog = incarico;
                }

                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);

            },
            getData(data) {
                return moment.toDate(data);
            }
        },
        created() {
            this.loadIncarichi();
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
